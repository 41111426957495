import React, { useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import Tooltip from "@material-ui/core/Tooltip";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { Grid, Switch } from "@material-ui/core";
import MainContainer from "../../components/MainContainer/index.js";
import MainHeader from "../../components/MainHeader/index.js";
import Title from "../../components/Title/index.js";
import useQueues from "../../hooks/useQueues";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from 'material-ui-color';
import { InputAdornment } from "@material-ui/core";
import { IconButton } from "@material-ui/core";




const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},
	mainPaper: {
		display: "flex",
		flexWrap: "wrap",
		padding: theme.spacing(2),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	colorPicker: {
		width: 20,
		height: 20,
	},

}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);
	//const [collapse, setCollapse] = useState(false);

	const { findAll: findAllQueues } = useQueues();
	const [queues, setQueues] = useState([]);
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [color, setColor] = useState("");


	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);

				const color = data.find(setting => setting.key === "clientWaitingTime15").value;
				setColor(color);

			} catch (err) {
				toastError(err);
			}
		};


		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);
		}


		fetchSession();
		loadQueues();

	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	return (

		<MainContainer className={classes.container} >
			<MainHeader>
				<Title>{i18n.t("settings.title")}</Title>
			</MainHeader>
			<Paper className={classes.mainPaper} variant="outlined">
				<Grid container spacing={3}>
					{/*<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={i18n.t("settings.settings.timeCreateNewTicket.note")}>
					<Paper className={classes.paper} elevation={3}>
						<Typography variant="body1">
							{i18n.t("settings.settings.timeCreateNewTicket.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="timeCreateNewTicket-setting"
							name="timeCreateNewTicket"
							value={
								settings && settings.length > 0 && getSettingValue("timeCreateNewTicket")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="10">
								{i18n.t("settings.settings.timeCreateNewTicket.options.10")}
							</option>
							<option value="30">
								{i18n.t("settings.settings.timeCreateNewTicket.options.30")}
							</option>
							<option value="60">
								{i18n.t("settings.settings.timeCreateNewTicket.options.60")}
							</option>
							<option value="300">
								{i18n.t("settings.settings.timeCreateNewTicket.options.300")}
							</option>
							<option value="1800">
								{i18n.t("settings.settings.timeCreateNewTicket.options.1800")}
							</option>
							<option value="3600">
								{i18n.t("settings.settings.timeCreateNewTicket.options.3600")}
							</option>
							<option value="7200">
								{i18n.t("settings.settings.timeCreateNewTicket.options.7200")}
							</option>
							<option value="21600">
								{i18n.t("settings.settings.timeCreateNewTicket.options.21600")}
							</option>
							<option value="43200">
								{i18n.t("settings.settings.timeCreateNewTicket.options.43200")}
							</option>
							<option value="86400">
								{i18n.t("settings.settings.timeCreateNewTicket.options.86400")}
							</option>
							<option value="172800">
								{i18n.t("settings.settings.timeCreateNewTicket.options.172800")}
							</option>
						</Select>
					</Paper>
				</Tooltip>*/}

					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>

							<Typography variant="body1">
								{i18n.t("settings.settings.call.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="call-setting"
								name="call"
								value={
									settings && settings.length > 0 && getSettingValue("call")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="enabled">
									{i18n.t("settings.settings.call.options.enabled")}
								</option>
								<option value="disabled">
									{i18n.t("settings.settings.call.options.disabled")}
								</option>
							</Select>
						</Paper>
					</Grid>

					{/*PERMITIR MSG DE GRUPO*/}
					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.CheckMsgIsGroup.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="CheckMsgIsGroup-setting"
								name="CheckMsgIsGroup"
								value={
									settings && settings.length > 0 && getSettingValue("CheckMsgIsGroup")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="enabled">
									{i18n.t("settings.settings.CheckMsgIsGroup.options.enabled")}
								</option>
								<option value="disabled">
									{i18n.t("settings.settings.CheckMsgIsGroup.options.disabled")}
								</option>
							</Select>
						</Paper>
					</Grid>

					{/*PERMITIR REAÇÃO DE MENSAGEM*/}
					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.checkReaction.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="checkReaction-setting"
								name="checkReaction"
								value={
									settings && settings.length > 0 && getSettingValue("checkReaction")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="enabled">
									{i18n.t("settings.settings.checkReaction.options.enabled")}
								</option>
								<option value="disabled">
									{i18n.t("settings.settings.checkReaction.options.disabled")}
								</option>
							</Select>
						</Paper>
					</Grid>

					{/*PERMITIR AUDIO*/}
					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.checkAudio.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="checkAudio-setting"
								name="checkAudio"
								value={
									settings && settings.length > 0 && getSettingValue("checkAudio")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="enabled">
									{i18n.t("settings.settings.checkAudio.options.enabled")}
								</option>
								<option value="disabled">
									{i18n.t("settings.settings.checkAudio.options.disabled")}
								</option>
							</Select>
						</Paper>
					</Grid>

					{/*ENCERRAR TICKET AUTOMATICAMENTE*/}
					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Switch
								checked={
									settings && settings.length > 0 && getSettingValue("closeTicketAuto") === "enabled"
								}
								color="primary"
								onChange={e => {
									const value = e.target.checked ? "enabled" : "disabled";
									handleChangeSetting({
										target: { name: "closeTicketAuto", value },
									});
								}}
								name="closeTicketAuto"
								inputProps={{ "aria-label": "secondary checkbox" }}
							/>
							<Typography variant="body1">
								{i18n.t("settings.settings.closeTicketAuto.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="timeTicketClose"
								name="timeTicketClose"
								value={
									settings && settings.length > 0 && getSettingValue("timeTicketClose")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
								disabled={
									settings && settings.length > 0 && getSettingValue("closeTicketAuto") !== "enabled"
								}
							>
								<option value="2">2 horas</option>
								<option value="5">5 horas</option>
								<option value="10">10 horas</option>0
								<option value="12">12 horas</option>
								<option value="24">24 horas</option>
								<option value="48">48 horas</option>
							</Select>
						</Paper>
					</Grid>

					{/*ENCERRAR TICKET PENDENTE AUTOMATICAMENTE*/}
					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Switch
								checked={
									settings && settings.length > 0 && getSettingValue("closePendingTicketAuto") === "enabled"
								}
								color="primary"
								onChange={e => {
									const value = e.target.checked ? "enabled" : "disabled";
									handleChangeSetting({
										target: { name: "closePendingTicketAuto", value },
									});
								}}
								name="closePendingTicketAuto"
								inputProps={{ "aria-label": "secondary checkbox" }}
							/>
							<Typography variant="body1">
								{i18n.t("settings.settings.closePendingTicketAuto.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="timePendingTicketClose"
								name="timePendingTicketClose"
								value={
									settings && settings.length > 0 && getSettingValue("timePendingTicketClose")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
								disabled={
									settings && settings.length > 0 && getSettingValue("closePendingTicketAuto") !== "enabled"
								}
							>
								<option value="5">5 minutos</option>
								<option value="10">10 minutos</option>
								<option value="15">15 minutos</option>
								<option value="30">30 minutos</option>
								<option value="60">1 hora</option>
								<option value="90">1 hora e 30 minutos</option>
							</Select>
						</Paper>
					</Grid>

					{/*MENSAGEM AGENDADAS*/}
					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.statusSchedules.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="statusSchedules"
								name="statusSchedules"
								value={
									settings && settings.length > 0 && getSettingValue("statusSchedules")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="open">{i18n.t("settings.settings.statusSchedules.options.open")}</option>
								<option value="pending">{i18n.t("settings.settings.statusSchedules.options.pending")}</option>
								<option value="closed">{i18n.t("settings.settings.statusSchedules.options.closed")}</option>
							</Select>
						</Paper>
					</Grid>


					<Grid item xs={12} sm={6}>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.queueSchedules.name")}
							</Typography>
							<Select
								margin="dense"
								variant="outlined"
								native
								id="queueSchedules"
								name="queueSchedules"
								value={
									settings && settings.length > 0 && getSettingValue("queueSchedules")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="0">Selecione uma fila</option>
								{queues.map(queue => (
									<option key={queue.id} value={queue.id}>{queue.name}</option>
								))}
							</Select>

						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper className={classes.paper} style={{ flexDirection: "column", alignItems: "flex-start" }}>	
							<Typography variant="h6">
								Configuração do alerta de cliente esperando
							</Typography>
							<Typography variant="body1">
								Selecione a cor correspondente ao tempo de espera do cliente
							</Typography>
							<div style={{ display: "flex", flexDirection: "row", width: "50%", justifyContent: "space-between", marginTop: 10 }}>
								<Typography variant="body1" style={{ display: "flex", marginRight: 10, alignItems: "center" }}>
									De 15 a 30 minutos
								</Typography>
								<TextField
									margin="dense"
									variant="outlined"
									id="clientWaitingTime15"
									name="clientWaitingTime15"
									label="Cor"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{
														backgroundColor: color,
														width: 20,
														height: 20,
														borderRadius: "50%",
														display: "inline-block",
														border: "1px solid #ccc"
													}}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<Tooltip title="Escolher cor">
													<IconButton
														size="small"
														color="default"
														onClick={() => setColorPickerModalOpen(!colorPickerModalOpen)}
													>
														<Colorize />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</div>

							{colorPickerModalOpen && (
									<ColorBox
										disableAlpha
										hslGradient={false}
										style={{ margin: "10px auto 0" }}
										value={ settings && settings.length > 0 && getSettingValue("clientWaitingTime15") }
										onChange={val => {
											setColor(`#${val.hex}`);
											handleChangeSetting({
												target: { name: "clientWaitingTime15", value: `#${val.hex}` },
											});
										}}
									/>
							)}


						</Paper>
					</Grid>



				</Grid>
			</Paper>

		</MainContainer>

	);
};

export default Settings;