import React, { useContext } from "react";
import { Route as RouterRoute, Redirect, useLocation } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
  const { isAuth, loading, user } = useContext(AuthContext);

  const location = useLocation();

  const allowedRoutesForUserApi = ["/connections", "/api", "/faq"];
  console.log("Route path:", rest.path);
  console.log("Currrent path:", location.pathname);

  if (!isAuth && isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
      </>
    );
  }

  if (isAuth && user?.profile === "userApi") {
    if (!allowedRoutesForUserApi.includes(location.pathname)) { 
      return (
        <>
          {loading && <BackdropLoading />}
          <Redirect to="/connections" />;
        </>
      );
    }
  }

  if (isAuth && !isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/", state: { from: location } }} />;
      </>
    );
  }

  return (
    <>
      {loading && <BackdropLoading />}
      <RouterRoute {...rest} component={Component} />
    </>
  );
};

export default Route;
